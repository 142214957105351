"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WooBasketBuilder = void 0;
var axios_1 = require("axios");
var WooBasketBuilder = function () {
    var button = document.querySelector('.js-basket-builder-submit');
    var guest_email = document.querySelector('.js-basket-builder-guest-email');
    var email = '';
    // Check for button
    if (button) {
        button.addEventListener('click', function () {
            email = guest_email.value ? guest_email.value : '';
            var discount_type = document.querySelector('input[type=radio]:checked');
            var discount_amount = document.querySelector('.js-basket-builder-discount-amount');
            var cart = document.querySelector('.js-basket-builder-cart');
            if (!discount_type || !cart) {
                button.textContent = 'Missing fields!';
                setTimeout(function () {
                    button.textContent = 'Generate Basket Link';
                }, 2000);
                return;
            }
            button.textContent = 'Generating...';
            axios_1.default
                .post('/api/basket-builder', {
                email: email,
                discount_type: discount_type.id,
                discount_amount: discount_amount ? discount_amount.value : false,
                cart: cart.value,
            })
                .then(function (response) {
                button.textContent = 'Link Generated!';
                var basketBuilderResult = document.querySelector('.js-basket-builder-result');
                var basketBuilderResultWrapper = document.querySelector('.js-basket-builder-result-wrapper');
                basketBuilderResult.setAttribute('data-key', response.data.url);
                basketBuilderResult.textContent = response.data.url;
                basketBuilderResultWrapper.style.display = 'block';
                setTimeout(function () {
                    guest_email.value = '';
                    discount_amount.value = '';
                    button.textContent = 'Generate Basket Link';
                }, 2000);
            });
        });
    }
};
exports.WooBasketBuilder = WooBasketBuilder;
