"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = void 0;
var Tabs = function () {
    var tabParents = document.querySelectorAll('.js-tab-parent');
    tabParents.forEach(function (tabParent) {
        tabParent.addEventListener('click', function (event) {
            document.dispatchEvent(new Event('updateAccordionHeights'));
            var key = tabParent.getAttribute('data-key');
            tabParents.forEach(function (parent) {
                parent.classList.remove('active');
            });
            tabParent.classList.add('active');
            var tabItems = document.querySelectorAll('.js-tab-item');
            tabItems.forEach(function (tabItem) {
                var itemKey = tabItem.getAttribute('data-key');
                if (itemKey === key) {
                    tabItem.classList.add('active');
                }
                else {
                    tabItem.classList.remove('active');
                }
            });
        });
    });
};
exports.Tabs = Tabs;
