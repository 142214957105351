"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Heights = void 0;
var vanilla_js_match_height_1 = require("vanilla-js-match-height");
var Heights = function () {
    new vanilla_js_match_height_1.default('.js-product-brand');
    new vanilla_js_match_height_1.default('.js-product-name');
    new vanilla_js_match_height_1.default('.js-product-price');
    new vanilla_js_match_height_1.default('.js-post-heading');
    new vanilla_js_match_height_1.default('.js-comparsion-link');
    new vanilla_js_match_height_1.default('.js-comparsion-row');
};
exports.Heights = Heights;
