"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Render = /** @class */ (function () {
    function Render() {
        // Set up actions to return html
        this.data = [
            // {
            // 	"class" : '.c-header__account',
            // 	"wrapper" : '.c-header__account',
            // 	"action" : 'account_state',
            // },
            {
                "class": '.c-header__qty',
                "wrapper": '.c-header__qty',
                "action": 'cart_count',
            },
            {
                "class": '.c-messages__notice',
                "wrapper": '.c-messages',
                "action": 'woo_messages',
            },
        ];
        // Run the renderer
        this.runRender();
    }
    /**
     * Run the render function
     */
    Render.prototype.runRender = function () {
        // Post request to the render API
        fetch("/api/render", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.data),
        })
            .then(function (response) { return response.json(); })
            .then(function (data) {
            data.forEach(function (element) {
                var htmlWrapper = document.querySelectorAll(element.wrapper);
                if (htmlWrapper) {
                    htmlWrapper.forEach(function (wrapper) {
                        // Check for the message wrapper
                        var messageWrapper = wrapper.closest(element.class) ? wrapper.closest(element.class) : wrapper.querySelector(element.class);
                        // Reset
                        wrapper.classList.remove('isHidden', 'u-hide');
                        // Check to see if need to hide element
                        if (element.html == 'hide') {
                            wrapper.classList.add('isHidden', 'u-hide');
                        }
                        else {
                            // Remove Hidden Calss
                            wrapper.classList.remove('isHidden', 'u-hide');
                            // Set HTMl to render
                            messageWrapper.innerHTML = element.html;
                            //  If WooCommerce hide after 5s
                            if (element.action == 'woo_messages') {
                                setTimeout(function () {
                                    // wrapper.classList.add('isHidden');
                                }, 6000);
                            }
                        }
                    });
                }
            });
        })
            .catch(function (error) {
            console.log(error);
        });
    };
    return Render;
}());
// Close for Woo Messages
var close = document.querySelector('.js-close-messages');
if (close) {
    close.addEventListener('click', function () {
        document.querySelector('.c-messages').classList.add('isHidden');
    });
}
// Re run render on update
window.addEventListener('load', function () {
    jQuery(document.body).on('updated_cart_totals', function () {
        new Render();
    });
    jQuery('.woocommerce-cart-form .remove').on('click', function () {
        setTimeout(function () {
            new Render();
        }, 250);
    });
});
exports.default = Render;
