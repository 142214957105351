"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Filters = /** @class */ (function () {
    function Filters() {
        this.init();
    }
    Filters.prototype.init = function () {
        // Get the submit and refine filter elements
        var submitFilters = document.querySelector('.js-submit-filters');
        var refineFilters = document.querySelector('.js-refine-filters');
        // Attach event listeners if elements are found
        if (submitFilters) {
            // Bind the 'this' context to the event handler
            submitFilters.addEventListener('click', this.handleFiltersSubmit.bind(this));
        }
        if (refineFilters) {
            // Bind the 'this' context to the event handler
            refineFilters.addEventListener('change', this.handleRefineChange.bind(this));
        }
    };
    Filters.prototype.handleFiltersSubmit = function (e) {
        // Extract data-types attribute value from the clicked element
        var types = (e.currentTarget.getAttribute('data-types') || '').split(',');
        var data = {};
        // Loop through types and selected inputs to collect filter data
        types.forEach(function (value) {
            var inputs = document.querySelectorAll("input[name=\"".concat(value, "\"]:checked"));
            inputs.forEach(function (input) {
                var name = input.getAttribute('name') || '';
                data[name] = data[name] || [];
                data[name].push(input.getAttribute('value') || '');
            });
        });
        var url = window.location.href;
        var refineIndex = url.indexOf('refine');
        // Check if 'refine' parameter exists in the URL
        if (refineIndex > -1) {
            var refine = url.substring(refineIndex + 7).split('&')[0];
            data['refine'] = [refine];
        }
        // Decide whether to reset filters or generate parameters
        if (Object.keys(data).length === 0) {
            this.resetFilters();
        }
        else {
            this.generateParams(data);
        }
    };
    Filters.prototype.handleRefineChange = function (e) {
        // Get the selected refine value
        var refine = e.currentTarget.value;
        var url = window.location.href;
        if (refine === '') {
            var refineIndex = url.indexOf('?refine');
            // Check if 'refine' parameter exists in the URL
            if (refineIndex > -1) {
                var params = url.substring(refineIndex + 7).split('&')[0];
                url = url.substring(0, refineIndex) + params;
                window.location.href = url;
            }
            else {
                this.resetFilters();
            }
        }
        else {
            var refineIndex = url.indexOf('refine');
            // Modify URL based on refine parameter presence
            if (refineIndex > -1) {
                url = url.replace(/refine=\w+/, "refine=".concat(refine));
            }
            else {
                if (url.indexOf('?') > -1) {
                    url = "".concat(url, "&refine=").concat(refine);
                }
                else {
                    url = "".concat(url, "?refine=").concat(refine);
                }
            }
            window.location.href = url;
        }
    };
    Filters.prototype.generateParams = function (data) {
        // Get the base URL and construct parameters
        var url = window.location.href.split('?')[0];
        var params = Object.keys(data)
            .map(function (index) { return "".concat(index, "=").concat(data[index].join(',')); })
            .join('&');
        // Navigate to the new URL with parameters
        window.location.href = "".concat(url).concat(params ? "?".concat(params) : '');
    };
    Filters.prototype.resetFilters = function () {
        // Get the base URL and reset filters
        var url = window.location.href.split('?')[0];
        window.location.href = url;
    };
    return Filters;
}());
// Export Filters class (for ES6 modules)
exports.default = Filters;
