"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
var Accordion = function () {
    // Options to limit to one active accordion
    var options = {
        limitToOneOpen: true,
    };
    // Set the triggers for the Accordions
    var accordionElements = document.querySelectorAll('.js-accordion-trigger');
    // Check if we have accordions
    if (accordionElements.length) {
        // Setup our accordaions array
        var accordions_1 = [];
        // Loop over the accordions
        accordionElements.forEach(function (accordionElement) {
            // Set our accord object
            var accordion = {
                element: accordionElement,
                parent: accordionElement.parentNode,
                content: accordionElement.parentNode.querySelector('.js-accordion-content'),
                height: 0,
                active: false,
            };
            // Check for our parent and out content
            if (accordion.parent && accordion.content) {
                // Push our accordion elements into the accordions array
                accordions_1.push(accordion);
                accordion.element.addEventListener('click', function () {
                    // Set the heights on click
                    setAccordionHeights(accordions_1);
                    // Toggle out active class
                    accordion.element.classList.toggle('active');
                    // Toggle our active state
                    accordion.active = !accordion.active;
                    // Check if the item is expanded or not
                    if (accordion.active) {
                        // Expand the accordion
                        if (options.limitToOneOpen === true) {
                            closeNonActiveAcorrdions(accordion, accordions_1);
                        }
                        // Set our content height based off the object height
                        accordion.content.style.height = accordion.height + 'px';
                    }
                    else {
                        // Close the accordion
                        // Set the height to 0 to collapse the content
                        setTimeout(function () {
                            accordion.content.style.height = '0px';
                        }, 0);
                    }
                });
            }
        });
        // Set the heights on load within our accordions array
        setAccordionHeights(accordions_1);
        // Run the helper on resize to allow the accordions to easily open
        window.addEventListener('resize', function () {
            setAccordionHeights(accordions_1);
        });
    }
    // Helper function to set the heights of the accordions
    function setAccordionHeights(accordions) {
        accordions.map(function (accordion) {
            // set height of content to auto
            accordion.content.style.height = 'auto';
            // store the height of the accordion
            accordion.height = accordion.content.scrollHeight;
            // set the height back to 0 to hide the accordion
            accordion.content.style.height = accordion.active ? accordion.height + 'px' : '0px';
        });
    }
    // Close all but the active accordions
    function closeNonActiveAcorrdions(accordionToExclude, accordions) {
        // Set an array of accrodions to close
        var accordionsToClose = accordions.filter(function (accordion) { return accordion !== accordionToExclude; });
        accordionsToClose.map(function (accordion) {
            // Close the accordion content
            accordion.content.style.height = '0px';
            // Remove Active Class
            accordion.element.classList.remove('active');
            // Set the status to active flase
            accordion.active = false;
        });
    }
};
exports.Accordion = Accordion;
