"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function MobileFilterTrigger() {
    // Cache frequently accessed elements
    var openFilters = document.querySelector('.js-open-filters');
    var filterContent = openFilters ? openFilters.nextElementSibling : null;
    // Attach event listeners
    if (openFilters && filterContent) {
        openFilters.addEventListener('click', handleFiltersToggle);
    }
    var filtersContainer = document.querySelector('.js-filters-container');
    if (filtersContainer) {
        filtersContainer.addEventListener('change', handleFilterChange);
    }
    function handleFiltersToggle(e) {
        e.preventDefault();
        var openFilters = e.target;
        var filterContent = openFilters.nextElementSibling;
        if (openFilters.textContent === 'Close Filters') {
            openFilters.textContent = 'Open Filters';
        }
        else {
            openFilters.textContent = 'Close Filters';
        }
        filterContent.classList.toggle('u-hide');
    }
    function handleFilterChange(e) {
        var filter = e.target;
        var form = filter.closest('form');
        if (form) {
            form.submit();
        }
    }
}
exports.default = MobileFilterTrigger;
