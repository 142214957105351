"use strict";
/**
 * WooCommerce
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var basket_builder_1 = require("./woocommerce/basket-builder");
var mobileFilterTrigger_1 = require("./woocommerce/mobileFilterTrigger");
var render_1 = require("./woocommerce/render");
var filters_1 = require("./woocommerce/filters");
window.addEventListener("DOMContentLoaded", function () {
    (0, basket_builder_1.WooBasketBuilder)();
    // Prices();
    (0, mobileFilterTrigger_1.default)();
    new render_1.default();
    new filters_1.default(); // Init the filters
});
/**
 * Non WooCommerce
 *
 */
var accordion_1 = require("./accordion");
var matchHeight_1 = require("./matchHeight");
var sliders_1 = require("./sliders");
var filters_2 = require("./filters");
var menu_1 = require("./menu");
var tabs_1 = require("./tabs");
var $ = jQuery;
window.addEventListener("DOMContentLoaded", function () {
    // Array.prototype.forEach.call(document.querySelectorAll("*"), (element: HTMLElement) => {
    // 	element.style.outline = "1px solid #" + (Math.random() * 0xffffff << 0).toString(16);
    // });
    (0, matchHeight_1.Heights)();
    (0, sliders_1.Sliders)();
    (0, accordion_1.Accordion)();
    (0, filters_2.Filter)();
    (0, menu_1.Menu)();
    (0, tabs_1.Tabs)();
    $(document).on("click", ".js-copy", function () {
        var key = $(this).attr("data-key");
        updateClipboard(this, key);
    });
    function updateClipboard(item, newClip) {
        navigator.clipboard.writeText(newClip).then(function () {
            console.log("Copied to clipboard");
            $(item).text("Copied!");
            setTimeout(function () {
                $(item).text(newClip);
            }, 2000);
        }, function () {
            console.log("Failed to copy to clipboard");
            $(item).text("Failed! Try again");
            setTimeout(function () {
                $(item).text(newClip);
            }, 2000);
        });
    }
    function disableOutOfStockOptions() {
        console.log("disabled");
        $(".variations select").each(function () {
            $(this)
                .find("option")
                .each(function () {
                if ($(this).attr("data-out-of-stock") === "true") {
                    $(this).prop("disabled", true);
                }
                else {
                    $(this).prop("disabled", false);
                }
            });
        });
    }
    // Run on page load
    disableOutOfStockOptions();
    // Run when variations are updated
    $(document).on("woocommerce_update_variation_values", disableOutOfStockOptions);
});
