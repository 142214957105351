"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sliders = void 0;
var splide_1 = require("@splidejs/splide");
var splide_extension_auto_scroll_1 = require("@splidejs/splide-extension-auto-scroll");
var Sliders = function () {
    /*
        Banner Slider
    */
    // This is used on the views/blocks/banner.twig component
    var bannerElements = document.querySelector('.js-banner-slider');
    bannerElements && new splide_1.default('.js-banner-slider', {
        type: 'loop',
        arrows: false,
        pagination: false,
        keyboard: false,
        drag: true,
        rewind: true,
        autoplay: true,
        speed: 1000,
        interval: 5000,
        pauseOnHover: false,
        gap: 40,
        snap: true,
        autoHeight: true, // Auto height for the banner slider
    })
        .mount(); // Init the banner slider
    var uspElements = document.querySelector('.js-usp-slider');
    uspElements && new splide_1.default('.js-usp-slider', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        pagination: false,
        autoplay: true,
        gap: 20,
        drag: true,
        rewind: true,
        speed: 1000,
        interval: 2000,
        arrows: false,
        mediaQuery: 'min',
        breakpoints: {
            767: {
                perPage: 2,
            },
            1023: {
                perPage: 3,
            },
            1439: {
                perPage: 4,
                autoplay: false,
                drag: false,
            },
        },
    })
        .mount(); // Init the banner slider
    var logosElements = document.querySelector('.js-logo-slider');
    logosElements && new splide_1.default('.js-logo-slider', {
        type: 'loop',
        gap: 50,
        pagination: false,
        arrows: false,
        mediaQuery: 'min',
        drag: 'free',
        focus: 'center',
        perPage: 2,
        autoScroll: {
            speed: 1,
        },
        breakpoints: {
            767: {
                perPage: 3, // Load 3 slides for iPad and up
            },
            1023: {
                perPage: 4, // Load 4 slides for iPad pro and up
            },
            1279: {
                perPage: 5, // Load 5 slides for Laptop and up
            },
        },
    })
        .mount({ AutoScroll: splide_extension_auto_scroll_1.AutoScroll }); // Init the logos slider
    var productFeed = document.querySelectorAll('.js-product-slider');
    for (var i = 0; i < productFeed.length; i++) {
        new splide_1.default(productFeed[i], {
            type: 'slide',
            perPage: 1,
            perMove: 1,
            gap: 20,
            pagination: false,
            arrows: true,
            mediaQuery: 'min',
            autoplay: false,
            breakpoints: {
                767: {
                    perPage: 2,
                },
                1023: {
                    perPage: 3,
                },
                1279: {
                    perPage: 4,
                },
            },
            classes: {
                arrows: 'splide__arrows',
                arrow: 'splide__arrow',
                prev: 'splide__arrow--prev',
                next: 'splide__arrow--next',
            },
        }).mount();
    }
};
exports.Sliders = Sliders;
