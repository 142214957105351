"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filter = void 0;
var $ = jQuery;
var Filter = function () {
    // Set the filters
    var filters = document.querySelectorAll('.js-filters');
    // Make sure we have filters on the page
    if (filters.length) {
        // Loop over the filters on the page
        filters.forEach(function (filter) {
            // Check when our filters change
            filter.addEventListener('change', function () {
                // Submit the wrapping form on change
                filter.closest('form').submit();
            });
        });
    }
};
exports.Filter = Filter;
