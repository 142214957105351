"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Menu = void 0;
var $ = jQuery;
var Menu = function () {
    var secondaryMenu = document.querySelector('.js-secondary-menu');
    var secondaryMenuTrigger = document.querySelector('.js-secondary-menu-trigger');
    if (secondaryMenu && secondaryMenuTrigger) {
        secondaryMenuTrigger.addEventListener('click', function () {
            secondaryMenu.classList.toggle('active');
            secondaryMenuTrigger.classList.toggle('active');
        });
    }
    $('.js-mobile-menu-close').on('click', function () {
        $('.js-mobile-menu').removeClass('active');
    });
    $('.js-mobile-menu-open').on('click', function () {
        $('.js-mobile-menu').addClass('active');
    });
    $('.js-mobile-item-trigger').each(function (i, e) {
        $(e).on('click', function () {
            $(this).toggleClass('active');
            $(this).next().slideToggle();
        });
    });
};
exports.Menu = Menu;
